.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s 50;
  animation-delay: 10s;
  -moz-animation-delay: 10s;
  -webkit-animation-delay: 10s;
}

.clicked {
  animation-play-state:paused;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noSelect:focus {
  outline: none;
}

.spanBlur {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

}


.marquee-item {
  background-image: url("./images/prog-languages.png");
  /* background-image: url("./images/languages-pattern-MEidL4vy.png"); */
  animation: marqueeTop 35s linear infinite;
}

@keyframes bounce {
  0%,20%,50%,80%,100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);

  }
  60% {
    transform: translateY(-15px)
  }
}

@keyframes marqueeTop{
  0% {
    top: 0;
  }
  100% {
    top: -957px;
  }
}