@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}
/* mobile menu hamburger button */
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color:transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke-width:5.5;
  stroke: white;
  stroke-linecap:round;
  filter: brightness(1.5);
  
}

.ham6 .top {
  stroke-dasharray: 40 172;

}

.ham6 .middle {
  stroke-dasharray: 40 111;
}

.ham6 .bottom {
  stroke-dasharray: 40 172;
}

.ham6.active .top {
  stroke-dashoffset:-132px;
}

.ham6.active .middle {
  stroke-dashoffset:-71px;
}

.ham6.active .bottom {
  stroke-dashoffset:-132px;
}


/* * mobile menu container */ 
.mobilenav--wrap {
  padding: 0;
  height: 100vh;
  width:250px;
  /* background: red; */
  -webkit-box-shadow: 0 0 3px 0 rgba(0,0,0,0.3); 
   box-shadow: 0 0 3px 0 rgba(0,0,0,0.3);
  top: 0;
  left: -320px;
  position: fixed;
  -webkit-transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
transition: all 0.3s ease;
z-index:1

}

.mobilenav--wrap.show--mobilenav {
  left: 0;
 
}
/* mobile menu */
.mobilenav {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
}

.mobilenav > ul {
  width: 100%;
  box-sizing: border-box;
  margin: 6rem 0 2rem 0;
}

.mobilenav ul li {
  position: relative;
  border-bottom: 1px solid #ccc;
  list-style:none;
  font-size: 1.6rem
}

.mobilenav ul li a {
  display: block;
  padding: 15px 20px;
  text-decoration: none;
  color: #333
}

.mobilenav ul li a:hover {
  background: #fff;
  color: red;
}


.mobilenav ul li:hover ul {
  display:block;
  margin-left: 0;
}


/* send button */

.send-button {
  margin-top: 15px;
  height: 34px;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.alt-send-button {
  height: 34px;
  transition: all .2s ease-in-out;
}

.send-text {
  display: block;
  margin-top: 10px;
  font: 700 12px 'Lato', sans-serif;
  letter-spacing: 2px;
}

.alt-send-button:hover {
  transform: translate3d(0px, -29px, 0px);
}


